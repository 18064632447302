.link-style-custom-design {
  font-weight: 500;
  color: black;
  text-decoration: none !important;
  font-size: 16px;
}

.dropdown-menu-custom {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 150px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.dropdown-item:hover {
  background: #00713d1a;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  background: white;
  border: 1px solid #ddd;
  width: auto;
  z-index: 11;
  display: flex;
  flex-direction: column;
}

.text-figma-green,
.text-figma-green li a {
  color: rgb(0, 113, 61);
  font-weight: 500;
}

.text-figma-green,
.text-figma-green li a:active {
  color: #00713d1a;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
