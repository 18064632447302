body {
  overflow-x: hidden !important;
}

* {
  margin: 0;
  padding: 0;
}
:root {
  --theme-primary: #00713d;
  --footer-secondary: #442b19;
}
@media (max-width: 991.98px) {
  .product-image {
    width: 120px !important;
    /* height: 120px !important; */
  }

  .offcanvas-collapse {
    position: fixed;
    top: 108px; /* Height of navbar */
    bottom: 0;
    left: -80%;
    width: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(100%);
  }
}

.bg-custom-light {
  background-color: #e7e7e7;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 460px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.footer {
  background: linear-gradient(180deg, #677425 64.6%, #5a3e29 82.6%);
  color: var(--color-white);
}
.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}
.footer-wave-path {
  fill: #f8f9fa;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: var(--color-white);
}

.footer-content-column ul li a {
  color: var(--color-white);
  text-decoration: none;
}

.footer-logo-link {
  display: inline-block;
}
.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #fffff2;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}
.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: #f2f2f2;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
  background-color: #fffff2 !important;
  color: var(--theme-primary) !important;
}
.button:last-of-type {
  margin-right: 0;
}
.footer-call-to-action-button {
  background: #ffffffcc !important;
  border-radius: 6px;
  padding: 10px 15px;
  color: #00713d;
  text-decoration: none;
}
.footer-call-to-action {
  background: #ffffff33;
  margin-top: 30px;
  padding: 40px 30px;
  backdrop-filter: blur(4px);
}
.footer-call-to-action-title {
  color: #fffff2;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: var(--color-white);
  text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
  color: var(--color-white);
  text-decoration: none;
}

.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: var(--footer-secondary);
}

.footer-social-link.linkedin {
  height: 26px;
  left: 9px;
  top: 11px;
  width: 26px;
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill 0.2s;
}

.footer-social-link.twitter {
  height: 28px;
  left: 62px;
  top: 3px;
  width: 28px;
}

.footer-social-link.youtube {
  height: 24px;
  left: 126px;
  top: 12px;
  width: 24px;
}

.footer-social-link.github {
  height: 34px;
  left: 172px;
  top: 7px;
  width: 34px;
}

.footer-copyright {
  /* background-color: var(--footer-secondary); */
  color: var(--color-white);
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: var(--color-white);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: var(--theme-primary) !important;
  text-decoration: none;
}

/* Media Query For different screens */
@media screen and (max-width: 479px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 520px;
    position: relative;
  }
  .footer-content-column {
    width: 50%;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 500px;
    position: relative;
  }
  .footer-content-column {
    width: 50%;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 500px;
    position: relative;
  }

  .footer-content-column {
    width: 50%;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 760px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 350px;
    position: relative;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 24.99%;
  }
}
@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}

.quantity-selector button {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.quantity-selector > :last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.new-version {
  position: fixed;
  bottom: 20px;
}
.new-version a {
  color: #2bd156;
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-wrapper button {
  padding: 20px;
  border: none;
  background: #d5d8e7;
  position: relative;
  outline: none;
  border-radius: 5px;
  color: #292d48;
  font-size: 18px;
}
.page-wrapper button .cart-item {
  position: absolute;
  height: 24px;
  width: 24px;
  top: -10px;
  right: -10px;
}
.page-wrapper button .cart-item:before {
  content: "1";
  display: block;
  line-height: 24px;
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-weight: 600;
  background: #2bd156;
  color: white;
  border-radius: 20px;
  text-align: center;
}
.page-wrapper button.sendtocart .cart-item {
  display: block;
  animation: xAxis 1s forwards cubic-bezier(1, 0.44, 0.84, 0.165);
}
.page-wrapper button.sendtocart .cart-item:before {
  animation: yAxis 1s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cart {
  position: fixed;
  top: 50%; /* Position it in the middle of the screen vertically */
  right: 20px; /* Keep it on the right side */
  width: 50px;
  height: 50px;
  background: #292d48;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transform: translateY(-50%);
}
.cart i {
  font-size: 25px;
  color: white;
}
.cart:before {
  content: attr(data-totalitems);
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -12px;
  right: -12px;
  background: #2bd156;
  line-height: 24px;
  padding: 0 5px;
  height: 24px;
  min-width: 24px;
  color: white;
  text-align: center;
  border-radius: 24px;
}
.cart.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

@keyframes xAxis {
  100% {
    transform: translateX(calc(50vw - 105px));
  }
}
@keyframes yAxis {
  100% {
    transform: translateY(calc(-50vh + 75px));
  }
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.carousel-style {
  top: -100px;
  position: absolute;
  left: 20px;
  color: black;
  bottom: 0px;
}

.carousel-style h1 {
  font-size: 16px;
}
.slick-prev,
.slick-next {
  background-color: var(--theme-primary) !important;
  border-radius: 50% !important;
  width: 34px !important;
  height: 34px !important;
}
.slick-prev:before,
.slick-next:before {
  font-size: 34px !important;
  opacity: 1.75 !important;
  line-height: 1.1 !important;
}
/* For medium screens and larger */
@media (min-width: 768px) {
  .carousel-style {
    top: unset !important;
    position: absolute;
    left: 20px;
    color: black;
    bottom: 0px;
  }

  .carousel-style h1 {
    font-size: 2.5rem;
  }
}

/* product hover animation */

.product-card {
  overflow: hidden; /* Ensures that zoom doesn't break out of the card */
}

.product-image-container {
  overflow: hidden;
}

.product-image {
  transition: transform 0.3s ease-in-out;
  width: 100px !important;
  height: 100px;
}

.product-image-container:hover .product-image {
  transform: scale(1.1); /* Zoom in when hovering */
}

/* Card Box shadow on hover */

/* Add this to your CSS file */
.card-hover {
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.card-hover:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Beautiful shadow on hover */
  transform: translateY(-5px); /* Optional: Slight lift effect */
  cursor: pointer;
}

/* three Product's slider */

.slick-next {
  right: -40px !important;
}

.size-slider-image {
  height: 550px;
}

.logo-size {
  width: 100px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.margin-top-important {
  margin-top: 0 !important;
}

/* Loader style (Protected Route) */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to center */
}

.loader-container-products {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh; /* Full height to center */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--theme-primary); /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: auto; /* Center the spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* src/components/ScrollToTopButton.css */
.scroll-to-top {
  padding: 10px 12px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--theme-primary);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  background-color: #fff;
  color: var(--theme-primary);
  border: 2px solid var(--theme-primary);
}

.scroll-to-top:focus {
  outline: none;
}

/* src/components/ScrollToTopButton.css */
.subscribe-btn {
  padding: 10px 12px;
  position: fixed;
  bottom: 20px;
  right: 80px;
  background-color: var(--theme-primary);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.3s;
}

.subscribe-btn:hover {
  background-color: #fff;
  color: var(--theme-primary);
  border: 2px solid var(--theme-primary);
}

.subscribe-btn:focus {
  outline: none;
}

@media (max-width: 768px) {
  .subscribe-btn {
    padding: 10px 12px;
    position: fixed;
    bottom: 70px;
    right: 20px;
    background-color: var(--theme-primary);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.3s;
  }
}
